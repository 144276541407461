/* FreeDetailsModal.css */
.details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.details-modal .modal-header {
  border: none;
  padding: 10px 20px;
}

.details-modal .modal-body {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto; /* Add a scrollbar for tall content */
}

.details-modal .carousel {
  max-width: 100%;
}

.details-modal .carousel-inner {
  text-align: center;
}

.details-modal .carousel-item img {
  max-width: 100%;
  max-height: 50vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.details-modal .carousel-caption h4 {
  font-size: 1.2rem;
  margin-top: 10px;
}

.details-modal .carousel-caption p {
  font-size: 1rem;
  margin-top: 10px;
  word-wrap: break-word;
}

/* Center the modal */
.modal-dialog {
  margin: 0 auto;
}

/* Center the modal vertically */
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
}

/* Center the close button */
.modal-header .close {
  margin-left: auto;
}

/* Maximimize button */
.maximize-button,
.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  padding: 5px;
  margin: 0;
  transition: color 0.3s ease;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}

.details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 80%; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the modal horizontally */
}