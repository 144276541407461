/* FreeCourseContent.css */

.free-course-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
 /*  background-color: #f5f5f5;
 /*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.free-course-content h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.free-course-content p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.5; /* Improve readability with slightly increased line height */
}

/* Style images to fit the available width while maintaining aspect ratio */
.free-course-content img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* Style the download button */
.download-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
/* Mobile Optimization */
@media (max-width: 768px) {
  .free-course-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .free-course-content h4 {
    font-size: 20px; /* Decrease font size for headings */
  }

  .free-course-content p {
    font-size: 14px; /* Decrease font size for paragraphs */
  }

  .download-button {
    font-size: 14px; /* Decrease font size for the button */
    padding: 8px 16px; /* Adjust button padding for smaller screens */
  }
}