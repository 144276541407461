.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 80px;
}

.button {
  margin-top: 30px;
  display: flex;
  gap: 20px; /* Adjust the gap between buttons */
}

.square-btn {
  background-color: #17bf9e;
  color: #fff;
  padding: 15px 20px; /* Adjust the padding to make it square */
  border-radius: 5px; /* Adjust the border-radius for rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-right: 10px; /* Add some spacing between the icon and text */
}
