.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 1.3rem;
}

.nav__item a {
  font-weight: 500;
  font-size: 1.1rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #1B5A7A;
}

.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }
  .nav__right {
    display: none;
  }

  .active__menu {
    display: block;
  }
}

/* Existing CSS */
.nav__right a {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
  text-decoration: none;
  transition: 0.3s;
}

.nav__right a:hover {
  color: #1B5A7A; /* Change the color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.nav__right p i {
  font-size: 1.3rem;
}

/* Add this CSS for the icon to align properly with the link */
.nav__right p {
  display: flex;
  align-items: center;
}

.nav__right p a {
  margin-right: 5px; /* Adjust the spacing between the icon and the link */
}

/* Rest of your CSS... */

.logo img {
  width: 100%; /* Make the logo width 100% of its container */
  height: auto; /* Allow the height to adjust proportionally */
  max-width: 340px; /* Limit the maximum width of the logo */
}

/* Add a media query for screens narrower than 768px */
@media screen and (max-width: 768px) {
  .logo img {
    max-width: 250px; /* Adjust the maximum width for smaller screens */
  }
}
