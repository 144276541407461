/* CourseContent.css */
.course-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.course-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Style the download button */
.download-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}

/* Media query for screens with a maximum width of 768px (typical for tablets and mobile devices) */
@media (max-width: 768px) {
  .course-content {
    padding: 10px; /* Reduce padding for smaller screens */
    margin: 10px 0; /* Reduce margin for smaller screens */
  }

  .course-content h3 {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  /* Style the download button for mobile */
  .download-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}
