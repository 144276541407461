/* Additional styles for the testimonials section */
.testimonial-section {
  background-color: #f7f7f7;
  padding: 60px 0;
  border-radius: 15px;
}

/* Additional styles for individual testimonials */
.testimonial-item {
  position: relative;
  background-color: #fff;
  padding: none;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  width: 80%; /* Increase the width as needed */
  margin: 0 auto; /* Center the testimonial item */
  transition: transform 0.5s ease; /* Slow down the animation */
  cursor: pointer;
}

/* Additional styles for the testimonial image */
.testimonial-image {
  background-size: cover;
  background-position: center;
  height: 500px; /* Adjust the height of the image container */
  transition: transform 0.5s ease; /* Add image hover effect */
}

/* Additional styles for the testimonial content container */
.testimonial-content {
  padding: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Background for the content with some transparency */
  border-radius: 15px;
}

/* Additional styles for the testimonial title */
.testimonial-content h2 {
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}

/* Additional styles for the testimonial description */
.testimonial-content p {
  margin: 0;
  color: #fff;
}

/* Additional styles for the "Read More" button */
.testimonial-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

/* Additional styles on button hover */
.testimonial-content button:hover {
  background-color: #0056b3;
}

/* Hover effect for the testimonial item */
.testimonial-item:hover {
  transform: scale(1.05); /* Increase size on hover */
}

/* Hover effect for the testimonial image */
.testimonial-image:hover {
  transform: scale(1.1); /* Zoom in image on hover */
}

/* Your testimonial header styles */
.testimonial-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700; /* Set the desired font-weight */
  line-height: 2rem; /* Set the desired line-height */
  color: #333; /* You can choose your desired color */
}
/* Media query for screens smaller than 768px (adjust as needed) */
@media screen and (max-width: 768px) {
  /* Adjust styles for smaller screens here */
  .testimonial-item {
    width: 100%;
  }
  .testimonial-image {
    height: 200px;
  }
  .testimonial-content h2 {
    font-size: 18px;
  }
  .testimonial-content p {
    font-size: 14px;
  }
}