@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap);
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  grid-column-gap: 1.3rem;
  column-gap: 1.3rem;
}

.nav__item a {
  font-weight: 500;
  font-size: 1.1rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #1B5A7A;
}

.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }
  .nav__right {
    display: none;
  }

  .active__menu {
    display: block;
  }
}

/* Existing CSS */
.nav__right a {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
  text-decoration: none;
  transition: 0.3s;
}

.nav__right a:hover {
  color: #1B5A7A; /* Change the color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.nav__right p i {
  font-size: 1.3rem;
}

/* Add this CSS for the icon to align properly with the link */
.nav__right p {
  display: flex;
  align-items: center;
}

.nav__right p a {
  margin-right: 5px; /* Adjust the spacing between the icon and the link */
}

/* Rest of your CSS... */

.logo img {
  width: 100%; /* Make the logo width 100% of its container */
  height: auto; /* Allow the height to adjust proportionally */
  max-width: 340px; /* Limit the maximum width of the logo */
}

/* Add a media query for screens narrower than 768px */
@media screen and (max-width: 768px) {
  .logo img {
    max-width: 250px; /* Adjust the maximum width for smaller screens */
  }
}

.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 80px;
}

.button {
  margin-top: 30px;
  display: flex;
  grid-gap: 20px;
  gap: 20px; /* Adjust the gap between buttons */
}

.square-btn {
  background-color: #17bf9e;
  color: #fff;
  padding: 15px 20px; /* Adjust the padding to make it square */
  border-radius: 5px; /* Adjust the border-radius for rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-right: 10px; /* Add some spacing between the icon and text */
}

.company-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .company-logo {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; /* Adjust the gap as needed */
  }
  
  .company-logo img {
    max-width: 100%; /* Make logos responsive */
  }
  
.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #0a2b1e;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
  height: 100%; /* Set the height to 100% to match the image height */
}

.about__img {
  height: 100%; /* Set the height to 100% of the container's height */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Hide any overflowing content */
}

.about__img img {
  width: 100%; /* Set the width to 100% to fit the container */
  height: auto; /* Allow the height to adjust proportionally */
  border-radius: 15px;
}


.about__content h2 {
  font-weight: 600;
}

/* ... Other CSS Rules ... */

/* Card Container */
.about__cards {
  display: flex;
  flex-direction: column; /* Display cards in a column */
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
  align-items: center; /* Center cards horizontally */
}

/* Card Container */
.about__cards {
  display: flex;
  flex-direction: column; /* Display cards in a column */
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
  align-items: center; /* Center cards horizontally */
}

/* Card Styles */
.about__card {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%; /* Expand the width to the container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start; /* Align items vertically at the top of the card */
  padding: 20px;
  margin-bottom: 20px; /* Add spacing between cards */
}

.about__card-icon {
  font-size: 2.5rem;
  margin-right: 15px; /* Spacing between icon and content */
  align-self: flex-start; /* Align the icon to the top of the card */
  color: #0a2b1e; /* Set the icon color */
}

.about__card-content {
  flex: 1 1; /* Allow content to grow and take available space */
  align-self: flex-start; /* Align the content to the top of the card */
  flex-direction: column; /* Display content in a column */
}

.about__card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0a2b1e; /* Set the title color */
  margin-bottom: 5px; /* Add some space between title and content */
}

.about__card-text {
  font-size: 1rem;
  color: #333; /* Set the text color */
}

/* DetailsModal.css */
.details-modal {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Adjusted alignment for small screens */
  justify-content: flex-start; /* Adjusted alignment for small screens */
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 100%; /* Ensure the modal fits within the screen width */
  margin: 10px; /* Add some margin for better spacing on small screens */
}

.details-modal .modal-header {
  border: none;
  padding: 10px 20px;
  text-align: center; /* Center the modal header text */
}

.details-modal .modal-body {
  padding: 10px; /* Reduced padding for small screens */
  max-height: 70vh; /* Adjusted maximum height for small screens */
  overflow-y: auto; /* Add a scrollbar for tall content */
}

.details-modal .carousel-inner {
  text-align: center;
}

.details-modal .carousel-item img {
  max-width: 100%;
  max-height: 50vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

/* Center the close button */
.modal-header .close {
  margin-left: auto;
}

/* Maximimize button */
.maximize-button,
.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  padding: 5px;
  margin: 0;
  transition: color 0.3s ease;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}

@media (max-width: 1024px) {
  .details-modal {
    max-width: 75%; /* Adjust the maximum width as needed */
  }
}
/* CourseContent.css */
.course-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.course-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Style the download button */
.download-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}

/* Media query for screens with a maximum width of 768px (typical for tablets and mobile devices) */
@media (max-width: 768px) {
  .course-content {
    padding: 10px; /* Reduce padding for smaller screens */
    margin: 10px 0; /* Reduce margin for smaller screens */
  }

  .course-content h3 {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  /* Style the download button for mobile */
  .download-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

/* Header styles */
.courses-header {
  text-align: center;
  margin-bottom: 20px; /* This adds margin to create a gap */
}

.courses-header h1 {
  font-size: 24px;
  color: #333; /* You can adjust the color as needed */
  margin-bottom: 10px; /* This adds spacing below the header */
}

/* Course card styles */
.single__course__item {
  padding: 15px;
}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px; /* This adds margin to the course images */
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #1B5A7A;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
}

.choose__img img {
  border-radius: 15px;
}

.choose__content {
  padding-right: 50px;
}

.choose__content h2 {
  font-weight: 600;
}

.choose__img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 777;
}

.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play__icon i {
  color: #17bf9e;
  font-size: 2rem;
  padding: 10px;
}

.single__feature h2 i {
  font-size: 2.5rem;
}

.single__feature h6 {
  font-weight: 600;
}

/* FreeDetailsModal.css */
.details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.details-modal .modal-header {
  border: none;
  padding: 10px 20px;
}

.details-modal .modal-body {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto; /* Add a scrollbar for tall content */
}

.details-modal .carousel {
  max-width: 100%;
}

.details-modal .carousel-inner {
  text-align: center;
}

.details-modal .carousel-item img {
  max-width: 100%;
  max-height: 50vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.details-modal .carousel-caption h4 {
  font-size: 1.2rem;
  margin-top: 10px;
}

.details-modal .carousel-caption p {
  font-size: 1rem;
  margin-top: 10px;
  word-wrap: break-word;
}

/* Center the modal */
.modal-dialog {
  margin: 0 auto;
}

/* Center the modal vertically */
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
}

/* Center the close button */
.modal-header .close {
  margin-left: auto;
}

/* Maximimize button */
.maximize-button,
.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  padding: 5px;
  margin: 0;
  transition: color 0.3s ease;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}

.details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 80%; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the modal horizontally */
}
/* FreeCourseContent.css */

.free-course-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
 /*  background-color: #f5f5f5;
 /*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.free-course-content h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.free-course-content p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.5; /* Improve readability with slightly increased line height */
}

/* Style images to fit the available width while maintaining aspect ratio */
.free-course-content img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* Style the download button */
.download-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
/* Mobile Optimization */
@media (max-width: 768px) {
  .free-course-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .free-course-content h4 {
    font-size: 20px; /* Decrease font size for headings */
  }

  .free-course-content p {
    font-size: 14px; /* Decrease font size for paragraphs */
  }

  .download-button {
    font-size: 14px; /* Decrease font size for the button */
    padding: 8px 16px; /* Adjust button padding for smaller screens */
  }
}
/* Additional styles for the testimonials section */
.testimonial-section {
  background-color: #f7f7f7;
  padding: 60px 0;
  border-radius: 15px;
}

/* Additional styles for individual testimonials */
.testimonial-item {
  position: relative;
  background-color: #fff;
  padding: none;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  width: 80%; /* Increase the width as needed */
  margin: 0 auto; /* Center the testimonial item */
  transition: transform 0.5s ease; /* Slow down the animation */
  cursor: pointer;
}

/* Additional styles for the testimonial image */
.testimonial-image {
  background-size: cover;
  background-position: center;
  height: 500px; /* Adjust the height of the image container */
  transition: transform 0.5s ease; /* Add image hover effect */
}

/* Additional styles for the testimonial content container */
.testimonial-content {
  padding: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Background for the content with some transparency */
  border-radius: 15px;
}

/* Additional styles for the testimonial title */
.testimonial-content h2 {
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}

/* Additional styles for the testimonial description */
.testimonial-content p {
  margin: 0;
  color: #fff;
}

/* Additional styles for the "Read More" button */
.testimonial-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

/* Additional styles on button hover */
.testimonial-content button:hover {
  background-color: #0056b3;
}

/* Hover effect for the testimonial item */
.testimonial-item:hover {
  transform: scale(1.05); /* Increase size on hover */
}

/* Hover effect for the testimonial image */
.testimonial-image:hover {
  transform: scale(1.1); /* Zoom in image on hover */
}

/* Your testimonial header styles */
.testimonial-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700; /* Set the desired font-weight */
  line-height: 2rem; /* Set the desired line-height */
  color: #333; /* You can choose your desired color */
}
/* Media query for screens smaller than 768px (adjust as needed) */
@media screen and (max-width: 768px) {
  /* Adjust styles for smaller screens here */
  .testimonial-item {
    width: 100%;
  }
  .testimonial-image {
    height: 200px;
  }
  .testimonial-content h2 {
    font-size: 18px;
  }
  .testimonial-content p {
    font-size: 14px;
  }
}
/* ProjectModal.css */

.project-modal {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  max-height: 70vh;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 5px;
  overflow: auto; /* Enable scrolling for the modal */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.project-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-modal-header-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.maximize-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #000;
  padding: 0;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}



.project-modal-content {
  flex-grow: 1;
  padding-bottom: 20px; /* Add padding to separate carousel from content */
  overflow-y: auto; /* Enable scrolling for the content */
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7); /* Add an opaque background */
  padding: 20px; /* Add padding to the content */
  border-radius: 5px; /* Add border-radius for rounded corners */
}

.carousel {
  max-width: 100%;
}

.carousel-inner {
  text-align: center;
}

.carousel-item img {
  max-width: 100%;
  max-height: 50vh; /* Adjust the maximum height as needed */
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.carousel-caption h4 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 10px;
  color: #ffffff; /* Set text color to white */
}

.carousel-caption h5 {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #ffffff;
}

.carousel-caption p {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 1rem;
  margin-top: 10px;
  word-wrap: break-word;
  color: #ffffff;
}

/* Style for the header in the carousel caption */
.carousel-caption h4 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Style for the sub-header in the carousel caption */
.carousel-caption h5 {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 5px;
}

/* Style for the content in the carousel caption */
.carousel-caption p {
  margin-top: 5px;
  margin-bottom: 15px;
}

/* Custom indicators for the carousel */
.carousel-indicators {
  justify-content: center;
  margin-top: 15px;
}

.carousel-indicators li {
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicators li.active {
  background-color: #fff;
}

.newsletter {
  background: #17bf9e;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
}
.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  background: rgba(226, 248, 248, 0.664);
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 500;
}

/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;
}

button.btn {
  background: #1B5A7A;
  color: #fff;
  padding: 7px 25px;
  border-radius: 0;
}

i {
  color: #1B5A7A;
}

