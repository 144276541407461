/* ProjectModal.css */

.project-modal {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  max-height: 70vh;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 5px;
  overflow: auto; /* Enable scrolling for the modal */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.project-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-modal-header-buttons {
  display: flex;
  gap: 10px;
}

.maximize-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #000;
  padding: 0;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}



.project-modal-content {
  flex-grow: 1;
  padding-bottom: 20px; /* Add padding to separate carousel from content */
  overflow-y: auto; /* Enable scrolling for the content */
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7); /* Add an opaque background */
  padding: 20px; /* Add padding to the content */
  border-radius: 5px; /* Add border-radius for rounded corners */
}

.carousel {
  max-width: 100%;
}

.carousel-inner {
  text-align: center;
}

.carousel-item img {
  max-width: 100%;
  max-height: 50vh; /* Adjust the maximum height as needed */
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.carousel-caption h4 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 10px;
  color: #ffffff; /* Set text color to white */
}

.carousel-caption h5 {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #ffffff;
}

.carousel-caption p {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 1rem;
  margin-top: 10px;
  word-wrap: break-word;
  color: #ffffff;
}

/* Style for the header in the carousel caption */
.carousel-caption h4 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Style for the sub-header in the carousel caption */
.carousel-caption h5 {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 5px;
}

/* Style for the content in the carousel caption */
.carousel-caption p {
  margin-top: 5px;
  margin-bottom: 15px;
}

/* Custom indicators for the carousel */
.carousel-indicators {
  justify-content: center;
  margin-top: 15px;
}

.carousel-indicators li {
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicators li.active {
  background-color: #fff;
}
