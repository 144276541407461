/* DetailsModal.css */
.details-modal {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Adjusted alignment for small screens */
  justify-content: flex-start; /* Adjusted alignment for small screens */
  min-height: 0; /* Allow the modal to be smaller than its content */
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 100%; /* Ensure the modal fits within the screen width */
  margin: 10px; /* Add some margin for better spacing on small screens */
}

.details-modal .modal-header {
  border: none;
  padding: 10px 20px;
  text-align: center; /* Center the modal header text */
}

.details-modal .modal-body {
  padding: 10px; /* Reduced padding for small screens */
  max-height: 70vh; /* Adjusted maximum height for small screens */
  overflow-y: auto; /* Add a scrollbar for tall content */
}

.details-modal .carousel-inner {
  text-align: center;
}

.details-modal .carousel-item img {
  max-width: 100%;
  max-height: 50vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
}

/* Center the close button */
.modal-header .close {
  margin-left: auto;
}

/* Maximimize button */
.maximize-button,
.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #555;
  padding: 5px;
  margin: 0;
  transition: color 0.3s ease;
}

.maximize-button:hover,
.close-button:hover {
  color: #007bff;
}

@media (max-width: 1024px) {
  .details-modal {
    max-width: 75%; /* Adjust the maximum width as needed */
  }
}