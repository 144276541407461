.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #0a2b1e;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
  height: 100%; /* Set the height to 100% to match the image height */
}

.about__img {
  height: 100%; /* Set the height to 100% of the container's height */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Hide any overflowing content */
}

.about__img img {
  width: 100%; /* Set the width to 100% to fit the container */
  height: auto; /* Allow the height to adjust proportionally */
  border-radius: 15px;
}


.about__content h2 {
  font-weight: 600;
}

/* ... Other CSS Rules ... */

/* Card Container */
.about__cards {
  display: flex;
  flex-direction: column; /* Display cards in a column */
  gap: 20px;
  margin-top: 30px;
  align-items: center; /* Center cards horizontally */
}

/* Card Container */
.about__cards {
  display: flex;
  flex-direction: column; /* Display cards in a column */
  gap: 20px;
  margin-top: 30px;
  align-items: center; /* Center cards horizontally */
}

/* Card Styles */
.about__card {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%; /* Expand the width to the container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start; /* Align items vertically at the top of the card */
  padding: 20px;
  margin-bottom: 20px; /* Add spacing between cards */
}

.about__card-icon {
  font-size: 2.5rem;
  margin-right: 15px; /* Spacing between icon and content */
  align-self: flex-start; /* Align the icon to the top of the card */
  color: #0a2b1e; /* Set the icon color */
}

.about__card-content {
  flex: 1; /* Allow content to grow and take available space */
  align-self: flex-start; /* Align the content to the top of the card */
  flex-direction: column; /* Display content in a column */
}

.about__card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0a2b1e; /* Set the title color */
  margin-bottom: 5px; /* Add some space between title and content */
}

.about__card-text {
  font-size: 1rem;
  color: #333; /* Set the text color */
}
