/* Header styles */
.courses-header {
  text-align: center;
  margin-bottom: 20px; /* This adds margin to create a gap */
}

.courses-header h1 {
  font-size: 24px;
  color: #333; /* You can adjust the color as needed */
  margin-bottom: 10px; /* This adds spacing below the header */
}

/* Course card styles */
.single__course__item {
  padding: 15px;
}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px; /* This adds margin to the course images */
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #1B5A7A;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
}
