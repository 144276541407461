.company-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .company-logo {
    display: flex;
    align-items: center;
    gap: 1rem; /* Adjust the gap as needed */
  }
  
  .company-logo img {
    max-width: 100%; /* Make logos responsive */
  }
  